<template>
    <div>
        <h6 class="mb-8">{{ labelTitle }}</h6>

        <apexchart
            type="pie"
            :options="chart_options"
            :series="chart_data"
        />

        <data-table
            :id="id"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="false"
            :show-head="false"
            :show-totals="false"
            :searchable="false"
            :clickable="false"
            :sortable="false"
            class="non-sticky"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row.label }}: {{ row.count | nibnut.number("0,0") }}</h6>
                <div v-if="row.categories" class="text-small text-gray">
                    {{ row.categories.join(", ") }}
                </div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <span v-if="field === 'count'">{{ row.count | nibnut.number("0,0") }}</span>
                <span v-else-if="field === 'label'">
                    {{ row.label }}
                    <div v-if="row.categories" class="text-small text-gray">
                        {{ row.categories.join(", ") }}
                    </div>
                </span>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>
    </div>
</template>

<script>
import is_report from "./IsReport"

export default {
    name: "DnsFilterTopTable",
    mixins: [is_report],
    watch: {
        labelTitle: "set_column_name"
    },
    mounted () {
        this.set_column_name()
    },
    methods: {
        reset () {
            this.filter("categories", null)
            this.refresh()
        },
        set_column_name () {
            this.columns.label.label = this.labelTitle
        },
        filter_rows (rows) {
            if(this.filtered_by_selection) {
                return rows.filter((row) => {
                    return this.selected_row_ids.indexOf(row.id) >= 0
                })
            }
            if(this.state.filter_on && this.state.filter) {
                return rows.filter((row) => {
                    if(this.state.filter_on === "categories") return row.categories.indexOf(this.state.filter) >= 0
                    if(this.state.filter_on.match(/_at$/i)) return !!row[this.state.filter_on] === !!parseInt(this.state.filter)
                    return row[this.state.filter_on] === this.state.filter
                })
            }
            return rows
        },
        row_color (row) {
            /*
                const category_colors = {}
                let category_color_index = 0
            */
        }
    },
    computed: {
        state_identifier () {
            return this.id
        },
        chart_raw_data () {
            const raw_data = {} // <category>: count
            this.records.forEach(record => {
                record.categories.forEach(category => {
                    if(!raw_data[category]) raw_data[category] = record.count
                    else raw_data[category] += record.count
                })
            })
            return raw_data
        },
        chart_options () {
            return {
                chart: {
                    id: `pie-chart-${this.id}`,
                    height: 320,
                    type: "pie",
                    toolbar: {
                        show: false
                    },
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            const selected_category = config.w.config.labels[config.dataPointIndex]
                            this.filter("categories", (this.state.filter === selected_category) ? null : selected_category)
                        }
                    }
                },
                legend: {
                    show: false
                },
                tooltip: {
                    y: {
                        formatter: (value) => {
                            return `${this.nibnut_filter("nibnut.number", [value, "0,0.0"])}%`
                        }
                    }
                },
                labels: Object.keys(this.chart_raw_data),
                dataLabels: {
                    enabled: true,
                    formatter: (value) => {
                        return `${this.nibnut_filter("nibnut.number", [value, "0,0.0"])}%`
                    }
                },
                theme: {
                    palette: "palette6"
                }
            }
        },
        chart_data () {
            const values = Object.values(this.chart_raw_data)
            const total = values.reduce((total, value) => total + value, 0) || 1

            return values.map(value => {
                return (value / total) * 100
            })
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        records: {
            type: Array,
            default () {
                return []
            }
        },
        labelTitle: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            columns: {
                label: { label: " ", sort: false },
                count: { label: " ", sort: false }
            },

            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "count",
                sort_dir: "desc",
                filter_on: "categories",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>
