<template>
    <div v-if="rows.length">
        <apexchart
            :height="160"
            type="line"
            :options="chart_options"
            :series="chart_data"
        />

        <h5>{{ report_date[0] | nibnut.date("MMM dd") }} - {{ report_date[1] | nibnut.date("MMM dd") }}</h5>
        <div class="columns">
            <div class="column col-6 col-sm-12">
                <dns-filter-top-table
                    id="dns-filter-top-threats"
                    :records="threats"
                    :label-title="$root.translate('Top Threats')"
                />
            </div>
            <div class="column col-6 col-sm-12">
                <dns-filter-top-table
                    id="dns-filter-top-sites"
                    :records="sites"
                    :label-title="$root.translate('Top Sites')"
                />
            </div>
        </div>
    </div>
</template>

<script>
import parseISO from "date-fns/parseISO"
import format from "date-fns/format"

import is_report from "./IsReport"

import DnsFilterTopTable from "./DnsFilterTopTable"

export default {
    name: "DnsFilterReport",
    mixins: [is_report],
    components: {
        DnsFilterTopTable
    },
    methods: {
        top_records (is_threat) {
            const raw_sites = {}
            this.records.forEach(record => {
                if(record.threat === is_threat) {
                    const domain = this.entity_records("dns_filter_domain").find(domain => domain.id === record.dns_filter_domain_id)
                    if(domain) {
                        if(!raw_sites[domain.domain]) raw_sites[domain.domain] = { label: domain.domain, categories: domain.categories, count: record.count }
                        else raw_sites[domain.domain].count += record.count
                    }
                }
            })
            return Object.values(raw_sites)
        }
    },
    computed: {
        state_identifier () {
            return "dns-filter-report"
        },
        report_date () {
            const dates = this.rows.map(row => row.reported_at)
            dates.sort()
            return [dates[0], dates[dates.length - 1]]
        },
        chart_raw_data () {
            const data = {
                Sites: {},
                Threats: {}
            }
            const dates = []
            this.rows.forEach(row => {
                const type = row.threat ? "Threats" : "Sites"
                const date = parseISO(row.reported_at)
                const index = format(date, "yyyy-MM-dd")
                if(dates.indexOf(index) < 0) dates.push(index)
                if(!data[type][index]) data[type][index] = 1
                else data[type][index]++
            })
            dates.sort()

            const raw_data = []
            Object.keys(data).forEach((type, index) => {
                raw_data[index] = { name: type, data: {} }
                dates.forEach(date => {
                    const date_object = parseISO(date)
                    const date_index = format(date_object, "MM-dd")
                    raw_data[index].data[date_index] = data[type][date] ? data[type][date] : 0
                })
            })

            return raw_data
        },
        chart_options () {
            const chart_raw_data = this.chart_raw_data
            const categories = Object.keys(chart_raw_data[0].data)

            return {
                chart: {
                    id: "line-dns-filter",
                    height: 160,
                    type: "line",
                    toolbar: {
                        show: false
                    }
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    type: "dateTime",
                    tickAmount: 15,
                    categories
                },
                yaxis: {
                    forceNiceScale: true,
                    labels: {
                        formatter: (value) => {
                            return this.$numeral(value).format("0,0")
                        }
                    }
                }
            }
        },
        chart_data () {
            const series = []
            const chart_raw_data = this.chart_raw_data
            chart_raw_data.forEach(serie => {
                series.push({
                    name: serie.name,
                    data: serie.data ? Object.values(serie.data) : []
                })
            })
            return series
        },
        threats () {
            return this.top_records(true)
        },
        sites () {
            return this.top_records(false)
        }
        /*
        roaming_clients () {
            const raw_sites = {}
            this.records.forEach(record => {
                if(record.roaming_name) {
                    if(!raw_sites[record.roaming_name]) raw_sites[record.roaming_name] = { client: record.roaming_name, sites: 0, threats: 0 }
                    if(record.threat) raw_sites[record.roaming_name].threats += record.count
                    else raw_sites[record.roaming_name].sites += record.count
                }
            })
            return Object.values(raw_sites)
        }
        */
    }
}
</script>
